/**
 * Footer
 */

import React, {useContext} from 'react';
import classNames from 'classnames';
import NextLink from 'next/link';
import {ucFirst} from 'utils/caseconverters';
import AppContext from 'Containers/Layouts/AppContext';
import Logo from 'Components/Logo';
import Mucf from 'Assets/img/logo-mucf.png';
import SocialMedia from './SocialMedia';
import s from './Footer.module.scss';

const Footer = ({
    socialMedia = {},
    info = '',
    columns = [],
}) => {
    const {currentTheme} = useContext(AppContext);

    let logoModifier = 'White';
    if(currentTheme === 'sensus'){
        logoModifier = 'Gray';
    } else if(currentTheme === 'gavleborg'){
        logoModifier = 'Red';
    }
    const logoUrl = 'https://www.sensus.se';
    const logoLabel = currentTheme === 'sensus' ? 'Till startsidan' : 'Till sensus.se';

    const columnModifier = columns.length % 4 === 0 ? 'Four' : (
        columns.length % 3 === 0 ? 'Three' : (
            columns.length % 2 === 0 ? 'Two' : 'One'
        )
    );

    const classes = classNames(
        s['Footer'],
        {[s['Footer--HasColumns']]: columns.length > 0},
        [s[`Footer--Columns${columnModifier}`]],
        {[s[`Footer--${ucFirst(currentTheme)}`]]: currentTheme},
    );

    return (
        <footer className={classes}>
            <div className={s['Footer__Container']}>
                {(columns.length > 0) &&
                    <nav className={s['Footer__Nav']} aria-label="Sidfot">
                        {columns.map((column, index) => (
                            <Column {...column} key={index} />
                        ))}
                    </nav>
                }

                <div className={s['Footer__Footer']}>
                    <div className={s['Footer__Row']}>
                        {(currentTheme !== 'sensus') &&
                            <a
                                className={s['Footer__Mucf']}
                                href="https://www.mucf.se/"
                                rel="noreferrer noopener"
                            >
                                <img src={Mucf.src} width={Mucf.width} height={Mucf.height} alt="Med stöd av MUCF" />
                            </a>
                        }

                        <NextLink href={logoUrl} aria-label={logoLabel}>
                            <Logo
                                className={s['Footer__Logo']}
                                modifier={logoModifier}
                                logoByTheme={false}
                            />
                        </NextLink>

                        {info && (
                            <div className={s['Footer__InfoWrapper']}>
                                <p className={s['Footer__Info']}>{info}</p>
                            </div>
                        )}
                    </div>

                    {socialMedia && socialMedia.title && (
                        <div className={s['Footer__Row']}>
                            <SocialMedia
                                {...socialMedia}
                            />
                        </div>
                    )}
                </div>
            </div>
        </footer>
    );
};

const Column = ({title = '', text = '', items = []}) => {
    return (
        <div className={s['Footer__Column']}>
            <h2 className={s['Footer__Title']}>{title}</h2>

            {text &&
                <div className={s['Footer__Item']}>
                    <div
                        className={s['Footer__Text']}
                        dangerouslySetInnerHTML={{__html: text}}
                    />
                </div>
            }

            {(items.length > 0) &&
                <ul className={s['Footer__List']}>
                    {items.map((item, index) => (
                        <Item {...item} key={index} />
                    ))}
                </ul>
            }
        </div>
    );
};

const Item = ({link = {}, text = ''}) => {
    return (
        <li className={s['Footer__Item']}>
            {text ? (
                <div
                    className={s['Footer__Text']}
                    dangerouslySetInnerHTML={{__html: text}}
                />
            ) : (
                <Link {...link} />
            )}
        </li>
    );
};

const Link = ({
    text = '', 
    href = '', 
    mail = '', 
    url = '', 
    isExternal = false
}) => {
    let link = href || mail;
    link = url || link;

    return (
        <a 
            className={s['Footer__Link']} 
            href={link}
            target={isExternal ? '_blank' : '_self'}
            rel={isExternal ? 'noopener noreferrer' : ''}
        >
            {text}
        </a>
    );
};

export default Footer;
