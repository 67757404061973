/**
 * Logo
 */

import React, {useContext} from 'react';
import classNames from 'classnames';
import AppContext from 'Containers/Layouts/AppContext';
import Sensus from '../../public/img/logo--red.svg';
import SensusWhite from '../../public/img/logo--white.svg';
import SensusGray from '../../public/img/logo--gray.svg';
import ADBDalarna from '../../public/img/adb-rcn-dalarna.svg';
import ADBGavleborg from '../../public/img/adb-diskrimineringsbyran-gavleborg.svg';
import ADBNorrbotten from '../../public/img/adb-rattighetscentrum-norrbotten.svg';
import ADBStockholmNorr from '../../public/img/adb-stockholm-norr.svg';
import ADBUppsala from '../../public/img/adb-adu-uppsala-black.svg';
import ADBVasterbotten from '../../public/img/adb-rcv-vasterbotten.svg';
import s from './Logo.module.scss';

const Logo = ({
    className = '',
    modifier = '',
    logoByTheme = true,
    asBackground = false,
}) => {
    const {siteName, currentTheme} = useContext(AppContext);

    // TODO: Use site name instead
    const logos = {
        sensus: Sensus,
        'sensus-White': SensusWhite,
        'sensus-Gray': SensusGray,
        'sensus-Red': Sensus,
        dalarna: ADBDalarna,
        gavleborg: ADBGavleborg,
        norrbotten: ADBNorrbotten,
        stockholmnorr: ADBStockholmNorr,
        uppsala: ADBUppsala,
        vasterbotten: ADBVasterbotten,
    };

    const logoName = logoByTheme ? currentTheme : 'sensus';
    const image = modifier ? logos[`${logoName}-${modifier}`] : logos[logoName];
    if(!image) {
        return null;
    }

    const alt = logoByTheme ? siteName : 'Sensus';
    const title = asBackground ? alt : null;
    const style = asBackground ? {
        backgroundImage: `url('${image.src}')`,
    } : {};

    const classes = classNames(
        s['Logo'],
        {[s['Logo--AsBackground']]: asBackground},
        {[s['Logo--Theme']]: logoByTheme},
        {[s[`Logo--${modifier}`]]: modifier},
        className,
    );

    return (
        <div
            className={classes}
            title={title}
            style={style}
        >
            {!asBackground &&
                <img
                    className={s['Logo__Image']}
                    src={image.src}
                    width={image.width}
                    height={image.height}
                    alt={alt}
                />
            }
        </div>
    );
};

export default Logo;
