/**
 * HeaderSubMenu
 */

import React, {useContext, useState, useEffect} from 'react';
import classNames from 'classnames';
import {ucFirst} from 'utils/caseconverters';
import AppContext from 'Containers/Layouts/AppContext';
import HeaderSubMenuPromotion from 'Components/HeaderSubMenuPromotion';
import s from './HeaderSubMenu.module.scss';

const HeaderSubMenu = ({items, promotion, visible, isOpen}) => {
    const {currentTheme} = useContext(AppContext);
    const [baseUrl, setBaseUrl] = useState('');

    useEffect(() => {
        let url = window.location.href;
        url = url.split('/')[2].split(':')[0];
        setBaseUrl(url);
    }, []);

    const hasPromotion = promotion && promotion.title;

    const classes = classNames(
        s['HeaderSubMenu'],
        {[s['HeaderSubMenu--Promotion']]: hasPromotion},
        {[s['HeaderSubMenu--Open']]: isOpen},
        {[s[`HeaderSubMenu--${ucFirst(currentTheme)}`]]: currentTheme},
    );

    return (
        <div
            className={classes}
            aria-expanded={visible ? 'true' : 'false'}
            aria-hidden={visible ? 'false' : 'true'}>
            <div className={s['HeaderSubMenu__Wrapper']}>
                <div className={s['HeaderSubMenu__Navigation']}>
                    {items && items.map((item, index) => {
                        const isExternalLink = item.externalLinkHref &&
                            item.externalLinkTitle && Object.keys(item.page).length === 0;
                        const link = {
                            href: isExternalLink ? item.externalLinkHref : item.page.href,
                            title: isExternalLink ? item.externalLinkTitle : item.page.title,
                        };

                        return (
                            <div key={index} className={s['HeaderSubMenu__Links']}>
                                <a
                                    href={link.href}
                                    target={isExternalLink ? '_blank' : null}
                                    rel={isExternalLink ? 'noopener noreferrer' : ''}
                                    className={classNames(
                                        s['HeaderSubMenu__Link'],
                                        {[s['HeaderSubMenu__Link--External']]: isExternalLink},
                                        {[s['HeaderSubMenu__Link--HasSubPages']]: item.items && item.items.length > 0},
                                    )}
                                >
                                    {link.title}
                                </a>

                                {item.items && (
                                    <div className={s['HeaderSubMenu__SubLinks']}>
                                        {item.items.map((subItem, index) => {
                                            const isExternalLink = !subItem.href.includes(baseUrl) && !subItem.href.startsWith('/');
                                            
                                            return (
                                                <a
                                                    href={subItem.href}
                                                    target={isExternalLink ? '_blank' : null}
                                                    className={classNames(
                                                        s['HeaderSubMenu__SubLink'],
                                                        {[s['HeaderSubMenu__SubLink--External']]: isExternalLink},
                                                    )}
                                                    key={index}
                                                >
                                                    {subItem.title}
                                                </a>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>

                {hasPromotion && (
                    <div className={s['HeaderSubMenu__Promotion']}>
                        <HeaderSubMenuPromotion {...promotion} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default HeaderSubMenu;
