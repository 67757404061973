/**
 * NavMainContent
 */

import React, {useState, useEffect, useContext} from 'react';
import classNames from 'classnames';
import AppContext from 'Containers/Layouts/AppContext';
import {ucFirst} from 'utils/caseconverters';
import NavMainContentItem from '../NavMainContentItem';
import HeaderMenu from 'Components/HeaderMenu';
import Browsealoud from 'Components/Browsealoud';
import s from './NavMainContent.module.scss';

const NavMainContent = ({
    menu = [],
    menuService = [],
    open = false,
    modifier = '',
    searchPageUrl = '',
    activeMenuIds = [],
    expandedSubNav = null,
    browsealoudScript = '',
}) => {
    const {currentTheme} = useContext(AppContext);
    const [baseUrl, setBaseUrl] = useState('');

    useEffect(() => {
        let url = window.location.href;
        url = url.split('/')[2].split(':')[0];
        setBaseUrl(url);
    }, []);

    const classes = classNames(
        s['NavMainContent'],
        {[s['NavMainContent--Open']]: open},
        {[s[`NavMainContent--${modifier}`]]: modifier},
        {[s[`NavMainContent--${ucFirst(currentTheme)}`]]: currentTheme},
    );

    const isSensus = currentTheme === 'sensus';
    const hasServiceMenu = !!menuService && menuService.length > 0;

    return (
        <div className={classes}>
            <div className={s['NavMainContent__Header']}>
                <HeaderMenu
                    disableHeadroom={true}
                    disableGoogleTranslate={isSensus ? true : false}
                    searchPageUrl={searchPageUrl}
                    menu={menu}
                />
                {menu && !!menu.length && (
                    <div className={s['NavMainContent__InnerWrapper']}>
                        <ul className={s['NavMainContent__List']}>
                            {menu.map((item, index) => (
                                <li key={index}>
                                    <NavMainContentItem
                                        {...item}
                                        id={`nav-main-item-${index}`}
                                        activeMenuIds={activeMenuIds}
                                        expandedSubNav={expandedSubNav}
                                        parentOpen={open}
                                        baseUrl={baseUrl}
                                        currentTheme={currentTheme}
                                    />
                                </li>
                            ))}
                        </ul>
                        {(hasServiceMenu || browsealoudScript) && (
                             <ul className={s['NavMainContent__ServiceList']}>
                                {hasServiceMenu && menuService.map((item, index) => (
                                    <li className={s['NavMainContent__ServiceListItem']} key={index}>
                                        <a
                                            href={item.url}
                                            className={s['NavMainContent__ServiceLink']}
                                        >
                                            {item.text}
                                        </a>
                                    </li>
                                ))}
                                {browsealoudScript &&
                                    <Browsealoud
                                        browsealoudScript={browsealoudScript}
                                        modifiers={['Mobile', 'MenuService', ucFirst(currentTheme)]}
                                    />
                                }
                            </ul>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default NavMainContent;
