/**
 * InfoBox
 */

import React from 'react';
import s from './InfoBox.module.scss';

const InfoBox = ({ wysiwyg, backgroundColor }) => (
    <div
        className={s['InfoBox']}
        style={backgroundColor ? { backgroundColor: `${backgroundColor}1A` } : {}}>
        <div
            className={s['InfoBox__Content']}
            dangerouslySetInnerHTML={{ __html: wysiwyg }}
        />
    </div>
);

export default InfoBox;
