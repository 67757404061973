/* global BrowseAloud */
/**
 * Browsealoud
 */

import React, {useState, useEffect, useRef, useContext, Fragment} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import AppContext from 'Containers/Layouts/AppContext';
import {ucFirst} from 'utils/caseconverters';
import s from './Browsealoud.module.scss';


const Browsealoud = ({ browsealoudScript, modifiers = [] }) => {
    const {currentTheme} = useContext(AppContext);

    const [loadBrowsealoud, setLoadBrowsealoud] = useState(false);
    const [baIsloading, setBaIsloading] = useState(false);
    const baLinkRef = useRef();

    const handleOnCLick = () => {
        if(!loadBrowsealoud) {
            setBaIsloading(true);
            setLoadBrowsealoud(true);
        }
    };

    const browsealoudClasses = classNames(
        s['Browsealoud'],
        {[s[`Browsealoud--${ucFirst(currentTheme)}`]]: currentTheme},
        ...modifiers.map(modifier => s[`Browsealoud--${modifier}`])
    );

    const browsealoudButtonClasses = classNames(
        s['Browsealoud__Button'],
        {[s['Browsealoud__Button--Loading']]: baIsloading},
    );

    return (
        <div className={browsealoudClasses}>
            <div className={s['Browsealoud__ButtonWrapper']}>
                <button
                    type="button"
                    onClick={handleOnCLick}
                    className={browsealoudButtonClasses}
                    ref={baLinkRef}
                >
                    <span className={s['Browsealoud__Loader']} />
                    Lyssna
                </button>

                <span className="sr-only">Aktivera talande webb</span>
            </div>
            <BrowsealoudScript
                baScript={browsealoudScript}
                load={loadBrowsealoud}
                finishLoading={(triggerClick = true) => {
                    setBaIsloading(false);
                    if(!loadBrowsealoud) {
                        setLoadBrowsealoud(true);
                    }
                    if(triggerClick) {
                        baLinkRef.current.click();
                    }
                }}
            />
        </div>
    )
}

const BrowsealoudScript = ({baScript, load, finishLoading}) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const appendScript = (initialLoad = true) => {
        const exists = document.getElementById('browsealoud-script');
        if (exists === undefined || exists === null) {
            const script = document.createElement('script');
            script.src = baScript;
            script.async = true;
            script.id = 'browsealoud-script';
            script.onload = function() {
                setIsLoaded(true);
                checkForVariable(1, () => {
                    finishLoading(!initialLoad);
                });
            };
            document.body.appendChild(script);
        }
    };

    const checkForVariable = (count, callback) => {
        if(count < 300) {
            setTimeout(() => {
                if(typeof(BrowseAloud) === 'undefined') {
                    checkForVariable(count+1, callback);
                } else {
                    callback();
                }
            }, 200);
        } else {
            console.error('BrowseAloud timed out');
        }
    };

    useEffect(() => {
        const storage = localStorage.getItem('TH_TOOLBAR_SETTINGS');
        if(storage) {
            const baSettings = JSON.parse(storage);
            const showToolbar = _.get(baSettings, 'toolbarOpenV2', false);
            if(showToolbar && !isLoaded) {
                appendScript();
            }
        }
    }, []);

    useEffect(() => {
        if(load) {
            if(!isLoaded) {
                appendScript(false);
            }
        }
    }, [load]);

    return (
        <Fragment />
    );
};

BrowsealoudScript.propTypes = {
    load: PropTypes.bool,
    finishLoading: PropTypes.func,
};

BrowsealoudScript.defaultProps = {
    load: false,
    finishLoading: () => {},
};

export default Browsealoud;

/*
<script type="text/javascript">
    var _baTheme=0, _baMode='Aktivera Talande Webb', _baUseCookies=true, _baHideOnLoad=true;
</script>
<style type="text/css">
    #__ba_panel { opacity: 0; height: 0; overflow: hidden; }
</style>
<script type="text/javascript" src="//www.browsealoud.com/plus/scripts/ba.js"></script>
*/