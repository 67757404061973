import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Script from 'next/script';
import classNames from 'classnames';
import * as Scroll from 'react-scroll';
import { snakeCaseToCamelCase } from 'utils';
import AppContext from './AppContext';
import NavMain from 'Components/NavMain';
import HeaderMenu from 'Components/HeaderMenu';
import InfoBox from 'Components/InfoBox';
import Footer from 'Components/Footer';
import WagtailUserbar from 'Components/WagtailUserbar';
import s from './BasePage.module.scss';

const BaseWrap = (Component) => (props) => {
    const camelProps = { ...snakeCaseToCamelCase(props) };

    return (
        <BasePage
            {...camelProps}
            _class={Component.name}
            displayName={Component.name.toLowerCase()}
        >
            <Component {...camelProps} />
        </BasePage>
    );
};

const BasePage = ({
    displayName = '',
    menu = null,
    menuMobile = null,
    menuService = null,
    disableGoogleTranslate = false,
    searchPageUrl = '',
    footer = null,
    children = null,
    infoBox = null,
    items = [],
    disableHeadroom = false,
    activeMenuIds = [],
    expandedSubNav = null,
    submenu = null,
    hideHeader = false,
    hideFooter = false,
    googleMapsV3Apikey = '',
    cookieScript = '',
    browsealoudScript = '',
    seo = {},
    siteName = 'Sensus',
    currentSite = 'sensus',
    currentTheme = 'sensus',
    wagtailUserbar = null,
    siteSetting = {},
    ai = {},
}) => {
    const { gtmId, matomoUrl } = siteSetting || {};

    // For handling base context in storybook it is added to all stories, check if
    // storybook settings exists, otherwise it is regular site and context can be created
    const {
        googleMapsV3Apikey: storybookGoogleMapsV3Apikey,
        siteName: storybookSiteName,
        currentSite: storybookSite,
        currentTheme: storybookTheme,
    } = useContext(AppContext);

    const globalGoogleMapsV3Apikey = storybookGoogleMapsV3Apikey !== '' ?
        storybookGoogleMapsV3Apikey : googleMapsV3Apikey;
    const globalSiteName = storybookSiteName !== '' ? storybookSiteName : siteName;
    const globalCurrentSite = storybookSite !== '' ? storybookSite : currentSite;
    const globalCurrentTheme = storybookTheme !== '' ? storybookTheme : currentTheme;

    let scrollPosition = -1;
    let scrollDirection = 0;

    const [expandedButton, setExpandedButton] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const pos = window.pageYOffset || document.documentElement.scrollTop;
            const direction = pos > scrollPosition ? -1 : 1;

            scrollPosition = pos;
            if (scrollDirection === direction) {
                return;
            }

            scrollDirection = direction;

            setExpandedButton(!expandedButton);
        };

        const handleAnchorScroll = () => {
            const shouldScroll = window.location.hash && items &&
                items.filter(item => item.component === 'AnchorDestination'
                    && encodeURIComponent(item.hash) === window.location.hash.substring(1)).length > 0;

            if (shouldScroll) {
                setTimeout(() => {
                    Scroll.scroller.scrollTo(window.location.hash.substring(1), {
                        duration: 350,
                        smooth: 'easeIn',
                    });
                }, 500);
            }
        };

        window.addEventListener('scroll', handleScroll);

        if (typeof window !== 'undefined') {
            handleAnchorScroll();
        }

        return window.removeEventListener('scroll', handleScroll);
    }, []);

    const appContext = {
        googleMapsV3Apikey: globalGoogleMapsV3Apikey,
        siteName: globalSiteName,
        currentSite: globalCurrentSite,
        currentTheme: globalCurrentTheme,
    };

    const classes = classNames(
        s['BasePage'],
        s['BasePage--Wrapper'],
        {[s[`BasePage--${displayName}`]]: displayName},
    );

    return (
        <>
            <Meta {...seo} />

            <AppContext.Provider value={appContext}>
                <div className={classes}>
                    {!hideHeader && menu && (
                        <HeaderMenu
                            disableHeadroom={disableHeadroom || !!submenu}
                            disableGoogleTranslate={disableGoogleTranslate}
                            searchPageUrl={searchPageUrl}
                            menu={menu}
                            menuService={menuService}
                            browsealoudScript={browsealoudScript}
                        />
                    )}

                    {infoBox && infoBox.wysiwyg && (
                        <InfoBox {...infoBox} />
                    )}

                    <div className={s['BasePage__Container']} role="document">
                        {children}
                    </div>

                    {!hideHeader && menuMobile && (
                        <NavMain
                            menuService={menuService}
                            menu={menuMobile}
                            expandedButton={expandedButton}
                            activeMenuIds={activeMenuIds}
                            expandedSubNav={expandedSubNav}
                            searchPageUrl={searchPageUrl}
                            browsealoudScript={browsealoudScript}
                        />
                    )}
                    {!hideFooter && footer && <Footer {...footer} />}
                </div>
            </AppContext.Provider>

            {!!wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}


            {!disableGoogleTranslate &&
                <>
                    <Script
                        id="google-translate-insert-script"
                        dangerouslySetInnerHTML={{__html: `
                            function googleTranslateElementInit() {
                                new google.translate.TranslateElement(
                                    {
                                        pageLanguage: 'sv',
                                        includedLanguages: 'sv,en,no,fi,dk,so,ar,ps,fa,de,es,uk',
                                        layout: google.translate.TranslateElement.FloatPosition.TOP_LEFT,
                                        autoDisplay: false
                                    },
                                    'google-translate-modal'
                                );
                                var langSelector = document.querySelector('.goog-te-combo');
                                if (langSelector) {
                                    langSelector.addEventListener('change', function (e) {
                                        var selectedLanguage = e.target.value;
                                        window.dataLayer = window.dataLayer || [];
                                        window.dataLayer.push({
                                            'event': 'translate',
                                            'language': selectedLanguage
                                        });
                                    });
                                }
                                var googleLink = document.querySelector('.goog-logo-link');
                                if (googleLink) {
                                    googleLink.setAttribute('rel', 'noopener noreferrer');
                                }
                            }
                        `}}
                    />
                    <Script src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit" />
                </>
            }
        </>
    );
};

BasePage.propTypes = {
    children: PropTypes.node,
    seo: PropTypes.object,
    wagtailUserbar: PropTypes.shape({
        html: PropTypes.string,
    }),
    displayName: PropTypes.string,
};

const Meta = ({
    htmlTitle = '',
    description = '',
    ogTitle = '',
    ogDescription = '',
    ogUrl = '',
    ogImage = '',
    ogType = '',
    twitterSite = '',
    twitterUser = '',
    twitterTitle = '',
    twitterDescription = '',
    twitterUrl = '',
    twitterImage = '',
    robots = '',
    canonicalLink = '',
}) => {
    return (
        <Head>
            <title>{htmlTitle}</title>

            {!!canonicalLink && (
                <link rel="canonical" href={canonicalLink} />
            )}

            {!!description && (
                <meta name="description" content={description} />
            )}
            {!!ogTitle && (
                <meta property="og:title" content={ogTitle} />
            )}
            {!!ogDescription && (
                <meta
                    property="og:description"
                    content={ogDescription}
                />
            )}
            {!!ogUrl && <meta property="og:url" content={ogUrl} />}
            {!!ogImage && (
                <meta property="og:image" content={ogImage} />
            )}
            {!!ogType && <meta property="og:type" content={ogType} />}
            {!!twitterSite && (
                <meta name="twitter:site" content={twitterSite} />
            )}
            {!!twitterUser && (
                <meta name="twitter:user" content={twitterUser} />
            )}
            {!!twitterTitle && (
                <>
                <meta name="twitter:card" content="summary" />
                <meta property="twitter:title" content={twitterTitle} />
                </>
            )}
            {!!twitterDescription && (
                <meta
                    property="twitter:description"
                    content={twitterDescription}
                />
            )}
            {!!twitterUrl && (
                <meta property="twitter:url" content={twitterUrl} />
            )}
            {!!twitterImage && (
                <meta property="twitter:image" content={twitterImage} />
            )}
            <meta name="robots" content={robots} />

            <link rel="preconnect" href="https://www.browsealoud.com" />
            <link rel="preconnect" href="https://plus.browsealoud.com" />
        </Head>
    );
};

Meta.propTypes = {
    htmlTitle: PropTypes.string,
    canonicalLink: PropTypes.string,
    description: PropTypes.string,
    ogTitle: PropTypes.string,
    ogDescription: PropTypes.string,
    ogUrl: PropTypes.string,
    ogImage: PropTypes.string,
    ogType: PropTypes.string,
    twitterSite: PropTypes.string,
    twitterUser: PropTypes.string,
    twitterTitle: PropTypes.string,
    twitterDescription: PropTypes.string,
    twitterUrl: PropTypes.string,
    twitterImage: PropTypes.string,
    robots: PropTypes.string,
};

export default BaseWrap;
