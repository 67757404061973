/**
 * NavMain
 */

import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import NavMainButton from './NavMainButton';
import NavMainContent from './NavMainContent';
import s from './NavMain.module.scss';

const NavMain = ({expandedButton = false, ...restProps}) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        document.addEventListener('keydown', (event) => {
            if (event.keyCode === 27 && isOpen) {
                setIsOpen(!isOpen);
            }
        });
    }, []);

    useEffect(() => {
        if (!isOpen) {
            document.body.classList.remove('no-scroll');
        } else {
            document.body.classList.add('no-scroll');
        }
    }, [isOpen]);

    const classes = classNames(
        s['NavMain'],
        {[s['NavMain--Open']]: isOpen},
    );

    return (
        <div className={classes}>
            <div className={s['NavMain__Overlay']}>
                <NavMainContent
                    open={isOpen}
                    modifier="Menu"
                    {...restProps}
                />
            </div>

            <NavMainButton
                onToggle={() => setIsOpen(!isOpen)}
                open={isOpen}
                expanded={expandedButton}
            />
        </div>
    );
};

export default NavMain;
