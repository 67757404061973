/**
 * SocialMedia
 */

import React, {useContext} from 'react';
import classNames from 'classnames';
import {ucFirst} from 'utils/caseconverters';
import AppContext from 'Containers/Layouts/AppContext';
import s from './SocialMedia.module.scss';

const SocialMedia = ({
    facebook = '',
    linkedIn = '',
    instagram = '',
}) => {
    const {
        siteName,
        currentTheme,
    } = useContext(AppContext);

    const items = [
        {
            name: 'Facebook',
            href: facebook,
        },
        {
            name: 'LinkedIn',
            href: linkedIn,
        },
        {
            name: 'Instagram',
            href: instagram,
        },
    ];

    const classes = classNames(
        s['SocialMedia'],
        {[s[`SocialMedia--${ucFirst(currentTheme)}`]]: currentTheme},
    );

    return (
        <nav className={classes} aria-label="Sociala medier">
            {items.map(({name, href}, index) => {
                if(href) {
                    const itemClasses = classNames(
                        s['SocialMedia__Link'],
                        [s[`SocialMedia__Link--${name}`]],
                    );
                    return (
                        <a
                            key={index}
                            aria-label={`${siteName} på ${name}`}
                            rel="noopener noreferrer"
                            href={href}
                            target="_blank"
                            className={itemClasses}
                        >
                            <span className="sr-only">{name}</span>
                        </a>
                    );
                } else {
                    return null;
                }
            })}
        </nav>
    );
};

export default SocialMedia;
