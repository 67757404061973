/**
 * BackgroundImage
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isWebpSupported } from 'utils/Helpers';
import s from './BackgroundImage.module.scss';

class BackgroundImage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            backgroundImage: null,
        };
    }

    componentDidMount() {
        isWebpSupported((isSupported) => this.loadImage(isSupported));
    }

    loadImage = (hasWebpSupport) => {
        const {image} = this.props;
        const backgroundImage = hasWebpSupport && image.webp
            ? `url('${image.webp}')`
            : `url('${image.src}')`;
        this.setState({backgroundImage});
    }

    render() {
        const {
            className,
            image,
            style = {},
            children,
        } = this.props;
        const { backgroundImage } = this.state;

        const inlineImageStyles = image?.focal
            ? {
                backgroundImage,
                backgroundPositionX: image.focal.x,
                backgroundPositionY: image.focal.y,
            }
            : { backgroundImage };

        return (
            <React.Fragment>
                <div
                    className={classNames(className, s['BackgroundImage'])}
                    style={{
                        ...inlineImageStyles,
                        ...style,
                    }}
                >
                    {children}
                </div>
            </React.Fragment>
        );
    }
}

BackgroundImage.propTypes = {
    image: PropTypes.object.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node
};

BackgroundImage.defaultProps = {
    image: {},
    className: '',
    style: {},
    children: null
};


export default BackgroundImage;
