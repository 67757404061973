/**
 * SearchAutocompleteResult
 */

import React from 'react';
import classNames from 'classnames';
import { dataLayerPush } from 'utils/Datalayer';
import s from './SearchAutocompleteResult.module.scss';

class SearchAutocompleteResult extends React.PureComponent {

    onHitClickHandler = () => {
        const {
            query,
            context,
        } = this.props;
        dataLayerPush({
            'event': 'searchClick',
            'searchType': context,
            'searchInput': query,
            'citySearch': null,
            'searchCategory': null
        });
    }

    render() {
        const {
            query,
            result,
            totalHits,
            searchPageUrl,
            autoCompleteRef,
            showNoResult,
            onHero,
        } = this.props;

        const classes = classNames(
            s['SearchAutocompleteResult'],
            {[s['SearchAutocompleteResult--NoHits']]: showNoResult},
            {[s['SearchAutocompleteResult--OnHero']]: onHero},
            {[s['SearchAutocompleteResult--HasHits']]: result && result.length > 0},
        );

        return (
            <div className={classes} ref={autoCompleteRef}>
                <div className={s['SearchAutocompleteResult__Content']}>
                    {result &&
                    result.length > 0 &&
                    result.map((hit, index) => {
                        let shortenPreamble = false;
                        const maxPreambleLength = onHero ? 100 : 500;
                        if (
                            hit.preamble &&
                            hit.preamble.length > maxPreambleLength
                        ) {
                            shortenPreamble = true;
                        }

                        return (
                            <AutocompleteHit
                                maxPreambleLength={maxPreambleLength}
                                shortenPreamble={shortenPreamble}
                                key={index}
                                onClick={this.onHitClickHandler}
                                {...hit}
                            />
                        );
                    })}

                    {showNoResult && (
                        <div className={s['SearchAutocompleteResult__NoHits']}>
                            Din sökning gav ingen träff
                        </div>
                    )}

                    {totalHits > 4 && (
                        <a
                            className={s['SearchAutocompleteResult__ShowAllHits']}
                            href={`${searchPageUrl}?query=${query}`}>
                            <span>Visa alla träffar ({totalHits})</span>
                        </a>
                    )}
                </div>
            </div>
        );
    }
}

const AutocompleteHit = ({
    preamble,
    href,
    title,
    shortenPreamble,
    maxPreambleLength,
    breadcrumbs,
    type,
    extra,
    onClick = () => null,
}) => {
    const isArrangement = type === 'arrangement';

    return (
        <a className={s['SearchAutocompleteResult__Hit']} href={href} onClick={onClick}>
            {breadcrumbs && (
                <div className={s['SearchAutocompleteResult__HitBreadcrumbs']}>
                    {breadcrumbs.map((item, index) => (
                        <span
                            className={s['SearchAutocompleteResult__HitBreadcrumb']}
                            key={index}>
                            {item.title}
                        </span>
                    ))}
                </div>
            )}
            {extra && extra.type && (
                <div className={s['SearchAutocompleteResult__Type']}>
                    {extra.readableType || extra.type}
                </div>
            )}
            <h3 className={s['SearchAutocompleteResult__HitTitle']}>{title}</h3>
            {preamble && (
                <p className={s['SearchAutocompleteResult__HitPreamble']}>
                    {preamble.substring(0, maxPreambleLength)}
                    {shortenPreamble && '...'}
                </p>
            )}

            {isArrangement && <AutoCompleteHitArrangementExtras {...extra} />}
        </a>
    );
};

const AutoCompleteHitArrangementExtras = ({
    price,
    location,
    date,
    isDistance,
}) => {
    return (
        <div className={s['SearchAutocompleteResult__MetaList']}>
            {isDistance && (
                <span className={s['SearchAutocompleteResult__Distance']}>
                    Distans
                </span>
            )}

            {location && (
                <div className={classNames(s['SearchAutocompleteResult__MetaItem'], s['SearchAutocompleteResult__MetaItem--Location'])}>
                    {location}
                </div>
            )}
            {price && (
                <div className={classNames(s['SearchAutocompleteResult__MetaItem'], s['SearchAutocompleteResult__MetaItem--Price'])}>
                    {price}
                </div>
            )}
            {date && (date.long || date.short) && (
                <div className={classNames(s['SearchAutocompleteResult__MetaItem'], s['SearchAutocompleteResult__MetaItem--Date'])}>
                    {date.short || date.long}
                </div>
            )}
        </div>
    );
};

export default SearchAutocompleteResult;
