/**
 * NavMainButton
 */

import React from 'react';
import classNames from 'classnames';
import s from './NavMainButton.module.scss';

const NavMainButton = ({
    onToggle,
    open,
    hide,
    hideDesktop,
    stuck,
    expanded,
}) => {
    const classes = classNames(
        s['NavMainButton'],
        {[s['NavMainButton--Hide']]: hide},
        {[s['NavMainButton--Open']]: open},
        {[s['NavMainButton--HideDesktop']]: hideDesktop},
        {[s['NavMainButton--Stuck']]: stuck},
        {[s['NavMainButton--HideDesktop']]: expanded && !open && !hideDesktop},
    );

    return (
        <div className={classes}>
            <button
                type="button"
                className={s['NavMainButton__Button']}
                onClick={() => onToggle()}
                aria-label={open ? 'Stäng' : 'Öppna'}
            >
                <span className={s['NavMainButton__StartCap']}></span>
                <span className={s['NavMainButton__Expander']}></span>
                <span className={s['NavMainButton__Text']}>Meny</span>
                <div className={s['NavMainButton__Main']}>
                    <span className={s['NavMainButton__Icon']}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </div>
            </button>
        </div>
    );
};

export default NavMainButton;
