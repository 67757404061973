/**
 * Button
 */

import React from 'react';
import classNames from 'classnames';
import * as Scroll from 'react-scroll';
import s from './Button.module.scss';

class Button extends React.PureComponent {

    anchorLinkOnClick = () => {
        const { link } = this.props;
        const hash = `${encodeURIComponent(link.url.substring(1))}`;

        Scroll.scroller.scrollTo(hash, {
            duration: 350,
            smooth: 'easeIn',
        });
    }

    render() {
        const { href, text, modifier, onClick, type, disabled, children, className} = this.props;

        const classes = classNames(
            s['Button'],
            {[s[`Button--${modifier}`]]: modifier},
            className,
        );

        const isButton = Boolean(!!type || !href);
        if (isButton) {
            return (
                <button
                    className={classes}
                    type={type || 'button'}
                    disabled={disabled}
                    onClick={onClick}
                >
                    {text || children}
                </button>
            );
        }

        const isAnchorLink = href && href.startsWith('#');
        return (
            <a
                className={classes}
                href={href}
                onClick={isAnchorLink ? this.anchorLinkOnClick : onClick}
            >
                {text || children}
            </a>
        );
    }
}

Button.defaultProps = {
    link: {},
    className: '',
    onClick: () => true,
};

export default Button;
