import {createContext} from 'react';

// Default values must be empty, default values are instead added to BaseWrap default props
const AppContext = createContext({
    googleMapsV3Apikey: '',
    siteName: '',
    currentSite: '',
    currentTheme: '',
});

export default AppContext;