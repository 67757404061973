import { email as emailValidator } from './Validators';

function objToQuery(params) {
    const queryParams = { ...params };
    Object.keys(queryParams).forEach((key) => {
        if (queryParams[key] === null || queryParams[key].length === 0) {
            delete queryParams[key];
        }
    });
    const query = Object.keys(queryParams)
        .map(
            (k) =>
                `${encodeURIComponent(k)}=${encodeURIComponent(queryParams[k])}`
        )
        .join('&');
    return query !== '' ? `?${query}` : '';
}

const idToName = (id, hayStack) => {
    const item = hayStack.find((i) => i.id === id);
    if (!item) {
        return '';
    }
    if (item.hasOwnProperty('name')) {
        return item.name;
    }
    if (item.hasOwnProperty('title')) {
        return item.title;
    }
};

const valueToName = (value, hayStack) => {
    const idHaystack = hayStack.map((h) => ({...h, id: h.value}));
    return idToName(value, idHaystack);
};


const checkEmailDomain = (emailValue='', mapping) => {
    if (emailValidator(emailValue) !== undefined) {
        return undefined;
    }
    const domain = emailValue.split('@')[1];
    return mapping[domain];
};

// https://developers.google.com/speed/webp/faq
function isWebpSupported(callback) {
    if (typeof Image === 'undefined') {
        return;
    }
    /*eslint-disable max-len*/
    const kTestImages = {
        lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
        lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
        alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
        animation: 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
    };
    /*eslint-enable max-len*/
    const img = new Image();
    img.onload = function () {
        const result = (img.width > 0) && (img.height > 0);
        callback(result);
    };
    img.onerror = function () {
        callback(false);
    };
    img.src = 'data:image/webp;base64,' + kTestImages['lossless'];
}

function getRandomInt() {
    return Math.floor(Math.random() * 1000);
}

function isIos() {
    return (
        [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod',
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes('Mac') &&
            'ontouchend' in document)
    );
}

export {
    objToQuery,
    idToName,
    valueToName,
    checkEmailDomain,
    isWebpSupported,
    getRandomInt,
    isIos,
};
