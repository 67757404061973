import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { snakeCaseToCamelCase } from 'utils';

const PDFWrap = (Component) => (props) => {
    const camelProps = { ...snakeCaseToCamelCase(props) };

    return (
        <PDFPage
            {...camelProps}
            _class={Component.name}
            displayName={Component.name.toLowerCase()}
        >
            <Component {...camelProps} />
        </PDFPage>
    );
};

export default PDFWrap;

const PDFPage = ({
    modelName,
    displayName,
    children,
    seo,
    siteSetting,
}) => {
    const type = modelName ? modelName : displayName;

    return (
        <>
            <Meta {...seo} />

            <div>
                {children}
            </div>
        </>
    );
};

PDFPage.defaultProps = {
    children: {},
    seo: {},
    modelName: '',
    displayName: '',
};

PDFPage.propTypes = {
    children: PropTypes.node,
    seo: PropTypes.object,
    modelName: PropTypes.string,
    displayName: PropTypes.string,
};

const Meta = ({
    seoHtmlTitle,
}) => {
    return (
        <Head>
            <title>{seoHtmlTitle}</title>
        </Head>
    );
};

Meta.defaultProps = {
};

Meta.propTypes = {
    seoHtmlTitle: PropTypes.string,
};
