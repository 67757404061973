/**
 * HeaderSubMenuPromotion
 */

import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import BackgroundImage from 'Components/BackgroundImage';
import s from './HeaderSubMenuPromotion.module.scss';

const HeaderSubMenuPromotion = ({ image, title, preamble, link }) => (
    <div className={s['HeaderSubMenuPromotion']}>
        {image && image.src && (
            <BackgroundImage
                className={s['HeaderSubMenuPromotion__Image']}
                image={image}
            />
        )}
        <div className={s['HeaderSubMenuPromotion__Content']}>
            <h3 className={s['HeaderSubMenuPromotion__Title']}>{title}</h3>
            {preamble && (
                <p className={s['HeaderSubMenuPromotion__Text']}>{preamble}</p>
            )}
        </div>

        {link && (
            <Link {...link} cardTitle={title} />
        )}
    </div>
);

const Link = ({
    title = '',
    href = '',
    cardTitle = '',
}) => {
    if(!title || !href) {
        return null;
    }

    const expandLinkText = title === 'Läs mer';
    return (
        <Button
            modifier="Small"
            href={href}
        >
            {title}
            {expandLinkText &&
                <span className="sr-only">{`om "${cardTitle}"`}</span>
            }
        </Button>
    );
};

Link.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string,
    cardTitle: PropTypes.string,
};

export default HeaderSubMenuPromotion;
